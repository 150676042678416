import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AuthLoggedIn = ({ loggedIn, children }) => {
    
    const navigate = useNavigate();

    useEffect(() => {
        if(!loggedIn)
            navigate('/login/');
    }, [loggedIn])

    if(loggedIn === true) {
        return children
    } else {
        return null;
    }
};

const AuthNotLoggedIn = ({ loggedIn, children }) => {
    
    const navigate = useNavigate();

    useEffect(() => {
        if(loggedIn) 
            navigate('/dashboard/');
        
    }, [loggedIn])

    if(loggedIn === false) {
        return children
    } else {
        return null;
    }
};

export {
    AuthLoggedIn,
    AuthNotLoggedIn
}