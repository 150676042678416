import { UilUser, UilUserSquare, UilSetting, UilSignout, UilApps } from '@iconscout/react-unicons'
import { UisTriangle } from '@iconscout/react-unicons-solid'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import './Horizontal.css'

const HorizontalHeader = (props) => {

    const [dropDownActive, setDropDownActive] = useState(false)

    const toggleAccountDropDown = () => {
        setDropDownActive(!dropDownActive);
    }

    const ddStyle = {
        marginTop: "0.5vw",
        marginRight: "0.5vw",
        transform: "translateY(10%)"
    }

    return (
        <div className="ban-header">
            <h3 className="ban-title"><a href="/" className='smart-text'>SmartLinked</a></h3>
        </div>
    );
}

export default HorizontalHeader;