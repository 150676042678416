import './Header.css'
import React, { useState, useLayoutEffect } from 'react';
import { UilUser, UilSignout, UilApps, UilHistory, UilInfoCircle, UilQuestionCircle, UilBookAlt, UilSetting, UilUserSquare } from '@iconscout/react-unicons'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '../MediaQuery';



function Header(props) {

    const [currentPage, setCurrentPage] = useState(-1)
    const [dropDownActive, setDropDownActive] = useState(false);
    const [hamburgerActive, setHamburgerActive] = useState(false);
    const location = useLocation();
    const isDesktop = useMediaQuery();

    const handlePageChange = (choice) => {
        setCurrentPage(choice);
        handleHamburgerActive(false);
    }

    const handleHamburgerActive = (hamburgerValue) => {
        if (document.getElementById('menu-hamburger')) {

            if (typeof hamburgerValue === 'boolean')
                setHamburgerActive(hamburgerValue);
            else
                setHamburgerActive(!hamburgerActive);

            if (hamburgerActive) {
                document.getElementById('menu-hamburger').classList.add("open");
                document.body.style.overflow = 'hidden';  // Disable scrolling
            } else {
                document.getElementById('menu-hamburger').classList.remove("open");
                document.body.style.overflow = 'auto';  // Enable scrolling
            }
        }
    }


    useLayoutEffect(() => {
        switch (location.pathname) {
            case '/dashboard/':
                handlePageChange(0);
                break;
            case '/dashboard/history/':
                handlePageChange(1);
                break;
            case '/dashboard/about/':
                handlePageChange(2);
                break;
            case '/dashboard/help/':
                handlePageChange(3);
                break;
            case '/dashboard/contact/':
                handlePageChange(4);
                break;
            case '/dashboard/settings/':
                handlePageChange(5);
                break;
            case '/dashboard/user/profile/':
                handlePageChange(-2);
                break;
            default:
                handlePageChange(-1);
                break;
        }

        if (!isDesktop) {
            handlePageChange(-3);
        }

    }, [location, isDesktop]);

    const toggleDropDown = () => {
        setDropDownActive(!dropDownActive);
    }


    const iconStyle = {
        float: "left",
        marginTop: `${(isDesktop ? '10.5px' : '5px')}`,
        marginLeft: "10px",
        marginRight: "10px",
    }

    const ddStyle = {
        marginTop: "0.5vw",
        marginRight: `${(isDesktop) ? '0.5vw' : '10px'}`,
        transform: "translateY(10%)"
    }

    const iconSize = "0.9em"
    const iconColor = "#374151"


    if (currentPage === -1)
        return;

    return (
        <div className="header-wrapper">
            {
                (!isDesktop) &&
                <div className="header-mobile">
                    <div className="header-item hd-hb-it" onClick={handleHamburgerActive} id="menu-hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <a href="/" id="hd-banner-text" className="header-item hd-tx-it smart-text">Smart<span className="linked-banner-text linked-text">Linked</span></a>
                    <div className="hd-hamb-wrapper">
                        <div className="hd-hamb-inner-wrapper">
                            <ul>
                                <Link style={{ textDecoration: 'none' }} to="/dashboard/">
                                    <li>
                                        <UilApps alt="App Icon" size={iconSize} color={(currentPage === 0) ? "black" : iconColor} style={iconStyle}></UilApps>
                                        Dashboard
                                    </li>
                                </Link>

                                <Link style={{ textDecoration: 'none' }} to="/dashboard/history/">
                                    <li>
                                        <UilHistory alt="App Icon" size={iconSize} color={(currentPage === 1) ? "black" : iconColor} style={iconStyle} />
                                        History
                                    </li>
                                </Link>


                                <Link style={{ textDecoration: 'none' }} to="/dashboard/about/">
                                    <li>
                                        <UilBookAlt alt="App Icon" size={iconSize} color={(currentPage === 2) ? "black" : iconColor} style={iconStyle} />
                                        About
                                    </li>
                                </Link>


                                {/* <Link style={{ textDecoration: 'none' }} to="/dashboard/help/">
                                    <li>
                                        <UilInfoCircle alt="App Icon" size={iconSize} color={(currentPage === 3) ? "black" : iconColor} style={iconStyle} />
                                        Help
                                    </li>
                                </Link> */}


                                <Link style={{ textDecoration: 'none' }} to="/dashboard/contact/">
                                    <li>
                                        <UilQuestionCircle alt="App Icon" size={iconSize} color={(currentPage === 4) ? "black" : iconColor} style={iconStyle} />
                                        Contact
                                    </li>
                                </Link>


                                <Link style={{ textDecoration: 'none' }} to="/dashboard/user/settings/">
                                    <li>
                                        <UilSetting alt="App Icon" size={iconSize} color={(currentPage === 5) ? "black" : iconColor} style={iconStyle} />
                                        Settings
                                    </li>
                                </Link>


                                <Link style={{ textDecoration: 'none' }} to="/dashboard/user/profile/">
                                    <li>
                                        <UilUserSquare size={iconSize} style={iconStyle} />
                                        Profile
                                    </li>
                                </Link>


                                <li>
                                    <UilSignout size={iconSize} style={iconStyle} />
                                    Sign out
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        props.user.profile_url != null
                            ?
                            <div className="hd-profile-img">
                                <img onClick={toggleDropDown} width="100%" height="auto" alt="SmartLinked user" src={props.user.profile_url}></img>
                            </div>
                            :
                            <div onClick={toggleDropDown} className="hd-profile-empty">
                                <UilUser color="#494950" size="20px" />
                            </div>

                    }
                    {
                        dropDownActive &&
                        <div className="hd-dd-wrapper">
                            {
                                props.isLoggedIn
                                    ?
                                    <ul>
                                        <li>
                                            <Link onClick={toggleDropDown} to="/dashboard/user/profile">
                                                <UilUserSquare size="1em" style={ddStyle} />profile
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={toggleDropDown} to="/dashboard/user/settings">
                                                <UilSetting size="1em" style={ddStyle} />settings
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="hd-dd-divider"></div>
                                        </li>
                                        <li>
                                            <span className="acc-sign-out" href="#" onClick={props.handleSignout}>
                                                <UilSignout size="1em" style={ddStyle} />sign out
                                            </span>
                                        </li>
                                    </ul>
                                    :
                                    <ul>
                                        <li>
                                            <Link onClick={toggleDropDown} to="/login/">
                                                <UilUserSquare size="1em" style={ddStyle} />log in
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={toggleDropDown} to="/signup/">
                                                <UilSetting size="1em" style={ddStyle} />sign up
                                            </Link>
                                        </li>
                                    </ul>
                            }

                        </div>
                    }
                </div>
            }

            {
                (isDesktop) &&
                (
                    // <div className="hd-banner-options">

                    <div>
                        <a href="/" id="hd-banner-text" className="header-item hd-tx-it smart-text">Smart<span className="linked-banner-text linked-text">Linked</span></a>
                        <ul className="hd-banner-options">
                            <Link to="/dashboard/">
                                <li style={{ backgroundColor: (currentPage === 0) ? "#e9ebef" : "white" }}>
                                    <UilApps alt="App Icon" size={iconSize} color={(currentPage === 0) ? "black" : iconColor} style={iconStyle}></UilApps>
                                    <span>App</span>
                                </li>
                            </Link>

                            <Link to="/dashboard/history/">
                                <li style={{ backgroundColor: (currentPage === 1) ? "#e9ebef" : "white" }}>
                                    <UilHistory alt="App Icon" size={iconSize} color={(currentPage === 1) ? "black" : iconColor} style={iconStyle} />
                                    <span>History</span>
                                </li>
                            </Link>
                        </ul>
                        <div className="hd-banner-divider"></div>
                        <ul className="hd-banner-options">
                            <Link to="/dashboard/about/">
                                <li style={{ backgroundColor: (currentPage === 2) ? "#e9ebef" : "white" }}>
                                    <UilBookAlt alt="App Icon" size={iconSize} color={(currentPage === 2) ? "black" : iconColor} style={iconStyle} />
                                    <span>About</span>
                                </li>
                            </Link>

                            {/* <Link to="/dashboard/help/">
                                <li style={{ backgroundColor: (currentPage === 3) ? "#e9ebef" : "white" }}>
                                    <UilInfoCircle alt="App Icon" size={iconSize} color={(currentPage === 3) ? "black" : iconColor} style={iconStyle} />
                                    <span>Help</span>
                                </li>
                            </Link> */}

                            <Link to="/dashboard/contact/">
                                <li style={{ backgroundColor: (currentPage === 4) ? "#e9ebef" : "white" }}>
                                    <UilQuestionCircle alt="App Icon" size={iconSize} color={(currentPage === 4) ? "black" : iconColor} style={iconStyle} />
                                    <span>Contact</span>
                                </li>
                            </Link>

                        </ul>
                        <div className="hd-banner-divider"></div>
                        <ul className="hd-banner-options">
                            <Link to="/dashboard/settings/">
                                <li style={{ backgroundColor: (currentPage === 5) ? "#e9ebef" : "white" }}>
                                    <UilSetting alt="App Icon" size={iconSize} color={(currentPage === 5) ? "black" : iconColor} style={iconStyle} />
                                    <span>Settings</span>
                                </li>
                            </Link>
                        </ul>

                        {
                            props.user.profile_url != null
                                ?
                                <img onClick={toggleDropDown} width="100%" height="auto" alt="SmartLinked user" className="hd-profile-img" src={props.user.profile_url}></img>
                                :
                                <div onClick={toggleDropDown} className="hd-profile-empty">
                                    <UilUser color="#494950" size="1.3vw" />
                                </div>
                        }

                        {
                            dropDownActive &&
                            <div className="hd-dd-wrapper">
                                <ul>
                                    <li>
                                        <Link onClick={toggleDropDown} to="/dashboard/user/profile/">
                                            <UilUserSquare size="1vw" style={ddStyle} />profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={toggleDropDown} to="/dashboard/settings/">
                                            <UilSetting size="1vw" style={ddStyle} />settings
                                        </Link>
                                    </li>
                                    <li>
                                        <div className="hd-dd-divider"></div>
                                    </li>
                                    <li>
                                        <span className="acc-sign-out" href="#" onClick={props.handleSignout}>
                                            <UilSignout size="1vw" style={ddStyle} />sign out
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        }

                    </div>
                )
            }
        </div>
    )

}

export default Header
