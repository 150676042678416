import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { useEffect, useState, Suspense, lazy } from 'react';
import { AuthLoggedIn, AuthNotLoggedIn } from './UI/AccountChecker';
import Header from './UI/Header/Header'
import PrivacyPolicy from './UI/Policies/privacy-policy';
import CookiesPolicy from './UI/Policies/cookie-policy'
import PopUpError from './UI/PopUpError';
import TermsAndConditions from './UI/Policies/terms-conditions';
import Disclaimer from './UI/Policies/disclaimer';
import './firebase';
// import TagManager from 'react-gtm-module';
import { getAuth, onAuthStateChanged, signOut, signInWithPopup, TwitterAuthProvider, FacebookAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";

const Welcome = lazy(() => import('./UI/Welcome/Welcome'));
const FadeElements = lazy(() => import('./UI/FadeObserver'));
const AboutPage = lazy(() => import('./UI/Dashboard/About/About'));
const History = lazy(() => import('./UI/Dashboard/History/History'));
const Signup = lazy(() => import('./UI/Register/Signup'));
const Help = lazy(() => import('./UI/Dashboard/Help/Help'));
const Contact = lazy(() => import('./UI/Dashboard/Contact/Contact'));
const ContactWlc = lazy(() => import('./UI/Dashboard/Contact/ContactWlc'));
const Settings = lazy(() => import('./UI/Dashboard/Settings/Settings'))

const Login = lazy(() => import('./UI/Register/Login'));
const ForgotPassword = lazy(() => import('./UI/Register/ForgotPassword'));
const ResetPassword = lazy(() => import('./UI/Register/ResetPassword'));
const Linked = lazy(() => import('./UI/Linked'));
const Profile = lazy(() => import('./UI/Dashboard/Profile/Profile'));


function App() {
    const [user, setUser] = useState({ name: null, email: null, profile_url: null });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    // TagManager.initialize({ gtmId: 'GTM-T6245FB8' });

    const loginWithFirebase = async (user) => {
        setIsLoggedIn(true);
        setUser({
            name: user.displayName,
            email: user.email,
            profile_url: user.photoURL,
            id: user.uid,
        })
    }

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            window.location.href = "/login/"
        }).catch((error) => {
            // An error happened.
        });
        
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                loginWithFirebase(user);
            } else {
            }
        });
    }, [])

    const handleErrorMessage = async (message, code) => {
        setErrorMessage(message);
        setErrorCode(code);
    }

    const closeError = async () => {
        setErrorMessage(null);
        setErrorCode(null);
    }

    const signUpWithEmail = async (email, password) => {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                
                window.gtag('event', 'sign_up', {
                  'event_category': 'User',
                  'event_label': 'User Signed Up'
                });
              
                // window.dataLayer.push({'event': 'login_success'});
            })
            .catch((error) => {
            });
    };

    const loginWithEmail = async (email, password) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
            })
            .catch((error) => {
                const errorCode = error.code;
                if(errorCode === "auth/user-not-found") {
                    handleErrorMessage('User was not found in our system', 'magenta')
                }
            });
    };

    const loginWithGoogle = async () => {
        const provider = new GoogleAuthProvider();

        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                window.gtag('event', 'sign_up', {
                    'event_category': 'User',
                    'event_label': 'User Signed Up'
                  });
                // window.dataLayer.push({'event': 'login_success'});
            }).catch((error) => {
                console.log("ERROR!");
                console.log(error);
            });
    };

    const loginWithTwitter = async () => {
        const provider = new TwitterAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                window.gtag('event', 'sign_up', {
                    'event_category': 'User',
                    'event_label': 'User Signed Up'
                  });
                // window.dataLayer.push({'event': 'login_success'});
            }).catch((error) => {
                console.log("ERROR!");
                console.log(error);
            });
    };

    const loginWithFacebook = async () => {
        const provider = new FacebookAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                window.gtag('event', 'sign_up', {
                    'event_category': 'User',
                    'event_label': 'User Signed Up'
                  });
                // window.dataLayer.push({'event': 'login_success'});
            })
            .catch((error) => {
                console.log("ERROR!");
                console.log(error);
            });
    };

    return (
        <CookiesProvider>
            <Router>
                <Header isLoggedIn={isLoggedIn} handleSignout={handleLogout} user={user}></Header>
                <PopUpError errorMessage={errorMessage} errorCode={errorCode} close={closeError}></PopUpError>
                <Suspense fallback={<div></div>}>

                    <Routes>

                        <Route path="/" element={
                            <>
                                <Welcome
                                    user={user}
                                    isLoggedIn={isLoggedIn}
                                    handleSignOut={handleLogout}
                                >
                                </Welcome>
                                <FadeElements />
                            </>
                        } />

                        <Route path="/contact/" element={
                            <ContactWlc></ContactWlc>
                        } />

                        <Route path="/privacy-policy/" element={
                            <PrivacyPolicy></PrivacyPolicy>
                        } />

                        <Route path="/cookie-policy/" element={
                            <CookiesPolicy></CookiesPolicy>
                        } />

                        <Route path="/terms-conditions/" element={
                            <TermsAndConditions></TermsAndConditions>
                        } />

                        <Route path="/disclaimer/" element={
                            <Disclaimer></Disclaimer>
                        } />

                        <Route path="/dashboard/" element={
                            <AuthLoggedIn loggedIn={isLoggedIn}>
                                <Linked handleErrorMessage={handleErrorMessage}></Linked>
                            </AuthLoggedIn>
                        } />

                        <Route path="/dashboard/about/" element={
                            <AuthLoggedIn loggedIn={isLoggedIn}>
                                <AboutPage></AboutPage>
                            </AuthLoggedIn>
                        } />

                        <Route path="/dashboard/history/" element={
                            <AuthLoggedIn loggedIn={isLoggedIn}>
                                <History></History>
                            </AuthLoggedIn>
                        } />

                        <Route path="/dashboard/settings/" element={
                            <AuthLoggedIn loggedIn={isLoggedIn}>
                                <Settings 
                                user={user}
                                logout={handleLogout}
                                ></Settings>
                            </AuthLoggedIn>
                        } />

                        <Route path="/dashboard/user/profile/" element={
                            <AuthLoggedIn loggedIn={isLoggedIn}>
                                <Profile 
                                user={user}
                                logout={handleLogout}
                                ></Profile>
                            </AuthLoggedIn>
                        } />

                        <Route path="/dashboard/contact/" element={
                            <AuthLoggedIn loggedIn={isLoggedIn}>
                                <Contact></Contact>
                            </AuthLoggedIn>
                        } />

                        <Route path="/dashboard/help/" element={
                            <AuthLoggedIn loggedIn={isLoggedIn}>
                                <Help></Help>
                            </AuthLoggedIn>
                        } />

                        <Route path="/login/" element={
                            <AuthNotLoggedIn loggedIn={isLoggedIn}>
                                <Login
                                    user={user}
                                    // handleNewUser={createNewUser}
                                    handleErrorMessage={handleErrorMessage}
                                    loginWithEmail={loginWithEmail}
                                    loginWithGoogle={loginWithGoogle}
                                    loginWithTwitter={loginWithTwitter}
                                    loginWithFacebook={loginWithFacebook}
                                >
                                </Login>
                            </AuthNotLoggedIn>

                        } />

                        <Route path="/signup/" element={
                            <AuthNotLoggedIn loggedIn={isLoggedIn}>
                                <Signup
                                    loggedIn={isLoggedIn}
                                    user={user}
                                    // handleNewUser={createNewUser}
                                    handleErrorMessage={handleErrorMessage}
                                    signUpWithEmail={signUpWithEmail}
                                    loginWithGoogle={loginWithGoogle}
                                    loginWithTwitter={loginWithTwitter}
                                    loginWithFacebook={loginWithFacebook}
                                >
                                </Signup>
                            </AuthNotLoggedIn>

                        } />

                        <Route path="/user/forgot-password/" element={
                            <AuthNotLoggedIn loggedIn={isLoggedIn}>
                                <ForgotPassword>
                                </ForgotPassword>
                            </AuthNotLoggedIn>

                        } />

                        <Route path="/user/reset-password/:token" element={
                            <ResetPassword>
                            </ResetPassword>
                        } />

                        {/* <Route path="/auth/twitter/callback/:token" element={
                            <TwitterLinked handleNewUser={createNewUser}>
                            </TwitterLinked>
                        } />

                        <Route path="/auth/google/callback/:token" element={
                            <GoogleLinked handleNewUser={createNewUser} />
                        } /> */}


                    </Routes>
                </Suspense>
            </Router>
        </CookiesProvider>
    )

}

export default App;