import { Helmet } from "react-helmet";
import HorizontalHeader from "../Header/HorizontalHeader";

function Disclaimer() {
    return (
        <>
            <Helmet>
                <title>Disclaimer | Smartlinked</title>
                <link rel="canonical" href="https://www.smartlinked.app/disclaimer/" />
                <meta name="description" content="Review our disclaimer to understand the limitations and exclusions of liability regarding the information and content provided on our website, services, and products." />
            </Helmet>
            <HorizontalHeader></HorizontalHeader>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
                }}
            />
            <div style={{margin: "20px"}} data-custom-class="body">
                <div>
                    <div
                        align="center"
                        className="MsoNormal"
                        data-custom-class="title"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                    >
                        <a name="_4r5vko5di6yg" />
                        <strong>
                            <span style={{ lineHeight: "150%", fontSize: 26 }}>DISCLAIMER</span>
                        </strong>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "center", lineHeight: "150%" }}
                    >
                        <a name="_l2jmcqu2bv4x" />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        data-custom-class="subtitle"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <br />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        data-custom-class="subtitle"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <span
                            style={{
                                color: "rgb(127,127,127)",
                                fontSize: 15,
                                textAlign: "justify"
                            }}
                        >
                            <strong>Last updated&nbsp;</strong>
                            <bdt
                                className="block-container question question-in-editor"
                                data-id="f06b270d-4b70-bc53-bef4-2d8996dff70b"
                                data-type="question"
                            >
                                <strong>August 10, 2023</strong>
                            </bdt>
                        </span>
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: "1.5" }}
                    >
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        style={{ textAlign: "justify", lineHeight: "1.5" }}
                    >
                        <br />
                    </div>
                    <div className="MsoNormal" data-custom-class="heading_1">
                        <a name="_xs0r05tcjblb" />
                        <strong>
                            <span style={{ lineHeight: "115%", fontSize: 19 }}>
                                WEBSITE DISCLAIMER
                            </span>
                        </strong>
                    </div>
                </div>
                <div style={{ lineHeight: "1.2" }}>
                    <br />
                </div>
                <div>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                            The information provided by{" "}
                            <bdt
                                className="block-container question question-in-editor"
                                data-id="1e91c6ac-db3b-ab40-09dc-333e7d471e6c"
                                data-type="question"
                            >
                                SmartLinked
                            </bdt>{" "}
                            (<bdt className="block-component" />
                            "we," "us," or "our"
                            <bdt className="statement-end-if-in-editor" />) on{" "}
                            <bdt className="block-component" />
                            <bdt className="question">
                                <a
                                    href="https://www.smartlinked.app"
                                    target="_blank"
                                    data-custom-class="link"
                                >
                                    https://www.smartlinked.app
                                </a>
                            </bdt>{" "}
                            (the <bdt className="block-component" />
                            "Site"
                            <bdt className="statement-end-if-in-editor" />)
                            <bdt className="block-component" /> is for general informational
                            purposes only. All information on{" "}
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                <bdt className="block-component" />
                                the Site
                                <bdt className="block-component" />
                            </span>{" "}
                            is provided in good faith, however we make no representation or
                            warranty of any kind, express or implied, regarding the accuracy,
                            adequacy, validity, reliability, availability, or completeness of any
                            information on{" "}
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <bdt className="block-component" />
                                    the Site
                                    <bdt className="block-component" />
                                </span>
                            </span>
                            . UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
                            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF{" "}
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <bdt className="block-component" />
                                    THE SITE
                                    <bdt className="block-component" />
                                </span>
                            </span>{" "}
                            OR RELIANCE ON ANY INFORMATION PROVIDED ON{" "}
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <bdt className="block-component" />
                                        THE SITE
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                            . YOUR USE OF{" "}
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <bdt className="block-component" />
                                        THE SITE
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>{" "}
                            AND YOUR RELIANCE ON ANY INFORMATION ON{" "}
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <bdt className="block-component" />
                                        THE SITE
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>{" "}
                            IS SOLELY AT YOUR OWN RISK.
                        </span>
                    </div>
                </div>
                <div style={{ lineHeight: "1.2" }}>
                    <br />
                </div>
                <div>
                    <div className="MsoNormal">
                        <a name="_x1u8x12nt00e" />
                    </div>
                    <bdt
                        className="block-container if"
                        data-type="if"
                        id="25d6783f-eaa7-3465-7bd8-31e107cc0931"
                    >
                        <bdt data-type="conditional-block">
                            <bdt
                                className="block-component"
                                data-record-question-key="external_disclaimer_option"
                                data-type="statement"
                            />
                            <bdt data-type="body">
                                <div className="MsoNormal" data-custom-class="heading_1">
                                    <strong>
                                        <span style={{ lineHeight: "115%", fontSize: 19 }}>
                                            EXTERNAL LINKS DISCLAIMER
                                            <br />
                                        </span>
                                    </strong>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ lineHeight: "1.2" }}>
                    <br />
                </div>
                <div>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                            <bdt className="block-component" />
                                            The Site
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                    <span style={{ fontSize: 15 }}>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            &nbsp;may contain (or you may be sent through{" "}
                                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                    <bdt className="block-component" />
                                                    the Site
                                                    <bdt className="block-component" />
                                                </span>
                                            </span>
                                            ) links
                                        </span>
                                    </span>
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        &nbsp;to other websites or content belonging to or originating
                                        from third parties or links to websites and features in banners
                                        or other advertising. Such external links are not investigated,
                                        monitored, or checked for accuracy, adequacy, validity,
                                        reliability, availability, or completeness by us. WE DO NOT
                                        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE
                                        ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
                                        THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR
                                        FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT
                                        BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                                        TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
                                        SERVICES.
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ lineHeight: "1.2" }}>
                    <br />
                </div>
                <div>
                    <bdt className="block-container if" data-type="if">
                        <bdt className="statement-end-if-in-editor" data-type="close" />
                    </bdt>
                    <div className="MsoNormal">
                        <a name="_wfmrqujylbbj" />
                    </div>
                    <bdt
                        className="block-container if"
                        data-type="if"
                        id="098cd9ba-027e-0afb-ec22-41e16cb68d79"
                    >
                        <bdt data-type="conditional-block">
                            <bdt
                                className="block-component"
                                data-record-question-key="professional_disclaimer_option"
                                data-type="statement"
                            />
                            <bdt data-type="body">
                                <div className="MsoNormal" data-custom-class="heading_1">
                                    <strong>
                                        <span style={{ lineHeight: "115%", fontSize: 19 }}>
                                            PROFESSIONAL DISCLAIMER
                                        </span>
                                    </strong>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ lineHeight: "1.2" }}>
                    <br />
                </div>
                <div>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <bdt className="block-component" />
                                        The Site cannot and does not contain{" "}
                                        <bdt
                                            className="block-container question question-in-editor"
                                            data-id="7744aaea-18c2-bcb8-7b80-f395643dfc29"
                                            data-type="question"
                                        >
                                            consulting
                                        </bdt>{" "}
                                        advice. The{" "}
                                        <bdt
                                            className="block-container question question-in-editor"
                                            data-id="89301322-2416-ef87-b4e0-ed76500d68ee"
                                            data-type="question"
                                        >
                                            consulting
                                        </bdt>{" "}
                                        information is provided for general informational and
                                        educational purposes only and is not a substitute for
                                        professional advice. Accordingly, before taking any actions
                                        based upon such information, we encourage you to consult with
                                        the appropriate professionals. We do not provide any kind of{" "}
                                        <bdt
                                            className="block-container question question-in-editor"
                                            data-id="019392d9-ec21-89ec-6801-8fc62f11838c"
                                            data-type="question"
                                        >
                                            consulting
                                        </bdt>{" "}
                                        advice.
                                        <bdt className="statement-end-if-in-editor" /> THE USE OR
                                        RELIANCE OF ANY INFORMATION CONTAINED ON{" "}
                                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                    <bdt className="block-component" />
                                                    THE SITE
                                                    <bdt className="block-component" />
                                                </span>
                                            </span>
                                        </span>{" "}
                                        IS SOLELY AT YOUR OWN RISK.
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ lineHeight: "1.2" }}>
                    <br />
                </div>
                <div>
                    <bdt className="block-container if" data-type="if">
                        <bdt className="statement-end-if-in-editor" data-type="close" />
                    </bdt>
                </div>
                <div>
                    <bdt className="block-component" />
                </div>
                <div>
                    <bdt data-type="conditional-block" style={{ textAlign: "start" }}>
                        <bdt data-type="body">
                            <div className="MsoNormal">
                                <bdt className="block-component" />
                            </div>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html:
                                        "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
                                }}
                            />
                        </bdt>
                    </bdt>
                </div>
                <div
                    style={{
                        color: "#595959",
                        fontSize: 14,
                        fontFamily: "Arial",
                        paddingTop: 16
                    }}
                >
                    This disclaimer was created using Termly's{" "}
                    <a
                        style={{ color: "rgb(48, 48, 241) !important" }}
                        href="https://termly.io/products/disclaimer-generator/"
                    >
                        Disclaimer Generator
                    </a>
                    .
                </div>
            </div>
        </>

    );
}

export default Disclaimer;