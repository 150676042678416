import { UilTimes, UilExclamationTriangle } from '@iconscout/react-unicons'
import './PopUp.css'

const PopUpError = (props) => {

    if(!props.errorCode)
        return null;

    return (
        <div className="error-message-wrapper">
            <div className="error-message-icon-wrapper">
                <UilExclamationTriangle color="white" size="1.7em"/>
            </div>
            <div className="error-message-text">
                <div onClick={props.close} className="error-close">
                    <UilTimes color="#EC4F4F" size="1.2em"/>
                </div>
                <span className="error-title">An Error Occured!</span>
                <br/>
                <span className="error-message">{props.errorMessage}</span>
                <br/>
                <span className="error-code">Error Code: <a href={`/errors/${props.errorCode}/`}>{props.errorCode}</a></span>
            </div>
        </div>
    )

}

export default PopUpError;