import { useState, useEffect } from 'react';

function useMediaQuery() {
    const query = "(min-width: 1200px)";
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        const media = window.matchMedia(query);
        const listener = (event) => setMatches(event.matches);
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [query]);

    return matches;
}

export default useMediaQuery;