// src/firebase.js
import 'firebase/auth'; // If you plan to use Firebase Authentication
import 'firebase/firestore'; // If you plan to use Cloud Firestore
import 'firebase/storage'; // If you plan to use Firebase Storage
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBZgaG6uhMP6sMjZip8hcg4BM_TQeF2qOY",
  authDomain: "smartlinked-44491.firebaseapp.com",
  projectId: "smartlinked-44491",
  storageBucket: "smartlinked-44491.appspot.com",
  messagingSenderId: "144171943228",
  appId: "1:144171943228:web:866368d6a9ca3e36efca39",
  measurementId: "G-53VGHS4Y5C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { app, auth };
